module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxMidth":900,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#000C1D","theme_color":"#000C1D","display":"minimal-ui","icon":"src/images/marc.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fb6121dcacca6a167ce6f2758f93ecd5"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
